import styled from 'styled-components';
import { tokenize } from '../albums';
import { Track } from './Track';
import { PiCaretLeft, PiPauseFill, PiPlayFill } from 'react-icons/pi';
import { AlbumArt } from './AlbumArt';
import { useContext } from 'react';
import { PlayerContext } from './PlayerProvider';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;

  @media screen and (max-width: 645px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const AlbumWrapper = styled.div`
  width: 250px;

  @media screen and (max-width: 645px) {
    width: 100%;
    max-width: 400px;
  }
`;

const AlbumName = styled.div`
  font-size: 3rem;
  font-weight: 600;
  color: #fff;

  @media screen and (max-width: 645px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 400px) {
    font-size: 1.5rem;
  }
`;

const TrackList = styled.ol`
  padding: 0;
  list-style: none;
  margin: 0;
`;

const Released = styled.div`
  font-size: 1rem;
  color: #999;
`;

const NameAndReleased = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 645px) {
    align-items: center;
    text-align: center;
  }
`;

const StyledBack = styled.button`
  border: none;
  background: none;
  text-align: left;
  padding: 0;
  margin: 0;
  appearance: none;
  cursor: pointer;
  color: #fff;
  outline: none;
  font-size: 1.2rem;
  display: inline-flex;
  gap: 4px;
  align-items: center;
`;

const PlayButton = styled.button`
  border: none;
  background: #27c;
  color: #fff;
  text-align: left;
  padding: 10px 14px;
  font-size: 1.2rem;
  margin: 0;
  appearance: none;
  outline: none;
  cursor: pointer;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-weight: 300;

  &:active {
    background: #38d;
  }

  @media screen and (max-width: 400px) {
    font-size: 1rem;
  }
`;

export const Album = ({ album, onBack }) => {
  const { song, setSong, playing } = useContext(PlayerContext);
  const art = `/music/${tokenize(album.name)}.jpg`;

  return (
    <>
      <div>
        <StyledBack type="button" onClick={onBack}>
          <PiCaretLeft /> Back
        </StyledBack>
      </div>
      <Wrapper>
        <AlbumWrapper>
          <AlbumArt src={art} alt={album.name} key={art} />
        </AlbumWrapper>
        <NameAndReleased>
          <div>
            <AlbumName>{album.name}</AlbumName>
            <Released>
              {album.tracks.length} tracks • {album.released}
            </Released>
          </div>
          <div>
            {song?.album !== album && (
              <PlayButton
                type="button"
                onClick={() =>
                  setSong({
                    album,
                    track: album.tracks[0],
                  })
                }
              >
                <PiPlayFill />
                <span>Play</span>
              </PlayButton>
            )}
            {song?.album === album && !playing && (
              <PlayButton
                type="button"
                onClick={() => document.querySelector('audio').play()}
              >
                <PiPlayFill />
                <span>Play</span>
              </PlayButton>
            )}
            {song?.album === album && playing && (
              <PlayButton
                type="button"
                onClick={() => document.querySelector('audio').pause()}
              >
                <PiPauseFill />
                <span>Pause</span>
              </PlayButton>
            )}
          </div>
        </NameAndReleased>
      </Wrapper>
      <TrackList>
        {album.tracks.map((track, index) => (
          <Track key={track} number={index + 1} track={track} album={album} />
        ))}
      </TrackList>
    </>
  );
};
