export function tokenize(name) {
  return name
    .replace(/[':,.!?()\-+]/g, '')
    .replace(/ /g, '_')
    .toLowerCase();
}

export const ALBUMS = [
  {
    name: 'Lego Guy',
    released: 'July 5, 2011',
    tracks: [
      'Decide',
      'This Way',
      'Destined to Be Lonely',
      'Lego Guy',
      'I Hate Me, Too',
      'You Bother Me',
      'The Sky Is Falling',
      "You Can't Take It from Me, Jolin",
      "Hey, Man, What's Wrong with Your Car?",
      "Let's Go to the Sun",
      'Across the River',
    ],
  },
  {
    name: 'Friends',
    released: 'May 6, 2013',
    tracks: [
      "It's the End of the World",
      '(Andy) Walk Away',
      'Friends',
      'Oh, Miranda',
      'The Three of Us',
      'Failing You',
      'About Love',
      "It's Me",
      'At the End of the World',
      'In My Arms',
    ],
  },
  {
    name: 'Mission to Mars',
    released: 'October 12, 2015',
    tracks: [
      'Ghosts (In the Sunshine)',
      'You Let Me Go Away',
      'Robot Girl',
      '(A Matter of) Time',
      'Mission to Mars',
      'Ctrl+Alt+Delete',
      'Dwayne Johnson',
      'Waiting...',
      'The Zookeeper',
      'Tom and the Andersens',
      'Music About Nothing',
    ],
  },
  {
    name: 'The Mathematician',
    released: 'June 2, 2017',
    tracks: [
      'Prelude',
      'This Is the Moment',
      "(I Don't) Like Dancing",
      'Galadriel',
      'Wanting Me',
      'Before We Fell In Love',
      "That's As Much As You Need to Know",
      'Jessica',
      'A Long Way Down',
      'The Mathematician',
    ],
  },
  {
    name: 'Country for You',
    released: 'Coming Soon',
    tracks: [
      'Country for You',
      'Arizona',
      'Only a Song',
      'Colorado Springs',
      'The Man I Used to Be',
      'Leave Me Alone',
      'Soulmates',
      'Hold on Tight',
      'Not Doing Fine',
      'The Great Guru',
    ],
  },
];
