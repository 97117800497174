import { useContext, useState } from 'react';
import styled from 'styled-components';
import { PlayerContext } from './PlayerProvider';
import { PiPauseFill, PiPlayFill } from 'react-icons/pi';

const TrackNumber = styled.div`
  width: 20px;
  text-align: right;
  color: #999;
  margin-right: 5px;
  font-size: 0.9rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 0.9rem;
  color: ${({ $playing }) => ($playing ? 'blue' : '#fff')};
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  width: 100%;
`;

const StyledLi = styled.li`
  & + & {
    border-top: 1px solid #444;
  }
`;

export const Track = ({ album, track, number }) => {
  const { song, setSong, playing } = useContext(PlayerContext);
  const [hovering, setHovering] = useState(false);

  return (
    <StyledLi
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <StyledButton
        type="button"
        onClick={() => {
          if (song?.track === track) {
            if (playing) {
              document.querySelector('audio').pause();
            } else {
              document.querySelector('audio').play();
            }
          } else {
            setSong({
              album,
              track,
            });
          }
        }}
      >
        {song?.track !== track && !hovering && (
          <TrackNumber>{number}</TrackNumber>
        )}
        {((song?.track === track && !playing) ||
          (hovering && song?.track !== track)) && (
          <TrackNumber>
            <PiPlayFill color={song?.track === track ? '#27c' : null} />
          </TrackNumber>
        )}
        {song?.track === track && playing && (
          <TrackNumber>
            <PiPauseFill color="#27c" />
          </TrackNumber>
        )}
        <div>{track}</div>
      </StyledButton>
    </StyledLi>
  );
};
