import { createContext, useMemo, useState } from 'react';

export const PlayerContext = createContext({ song: null });

export const PlayerProvider = ({ children }) => {
  const [song, setSong] = useState(null);
  const [playing, setPlaying] = useState(false);

  const value = useMemo(
    () => ({ song, setSong, playing, setPlaying }),
    [song, setSong, playing, setPlaying]
  );

  return (
    <PlayerContext.Provider value={value}>{children}</PlayerContext.Provider>
  );
};
