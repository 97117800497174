import styled from 'styled-components';
import { ALBUMS } from '../albums';
import { Album } from './Album';
import { AlbumCover } from './AlbumCover';

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`;

const AlbumGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;

const StyledTitle = styled.div`
  color: #fff;
  font-size: 1.2rem;
`;

export const Discography = ({ album, setAlbum }) => {
  return (
    <Wrapper>
      {!album && (
        <>
          <StyledTitle>Albums</StyledTitle>
          <AlbumGrid>
            {[...ALBUMS].reverse().map((albumItem) => (
              <AlbumCover
                key={albumItem.name}
                album={albumItem}
                onClick={() => {
                  if (albumItem !== album) {
                    window.scrollTo(0, 0);
                  }

                  setAlbum(albumItem);
                }}
              />
            ))}
          </AlbumGrid>
        </>
      )}
      {album && (
        <Album
          album={album}
          onBack={() => {
            window.scrollTo(0, 0);
            setAlbum(null);
          }}
        />
      )}
    </Wrapper>
  );
};
