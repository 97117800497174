import styled from 'styled-components';
import { tokenize } from '../albums';
import { AlbumArt } from './AlbumArt';

export function AlbumCover({ album, onClick }) {
  const art = `/music/${tokenize(album.name)}.jpg`;

  return (
    <StyledButton
      type="button"
      onClick={album.released === 'Coming Soon' ? null : onClick}
    >
      <AlbumArt src={art} />
      <AlbumName>{album.name}</AlbumName>
      <Tracks>
        {album.tracks.length} tracks •{' '}
        {album.released === 'Coming Soon'
          ? 'Coming Soon'
          : album.released.split(', ')[1]}
      </Tracks>
    </StyledButton>
  );
}

const StyledButton = styled.button`
  border: none;
  background: none;
  text-align: left;
  padding: 0;
  margin: 0;
  appearance: none;
  outline: none;
  ${({ onClick }) => !!onClick && `cursor: pointer`};
`;

const Tracks = styled.div`
  font-size: 0.8rem;
  color: #999;

  @media screen and (max-width: 450px) {
    font-size: 1rem;
  }
`;

const AlbumName = styled.div`
  font-size: 0.9rem;
  text-align: left;
  color: #fff;

  @media screen and (max-width: 450px) {
    font-size: 1.1rem;
  }
`;
