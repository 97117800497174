import { useState } from 'react';
import { Discography } from './components/Discography';
import { Player } from './components/Player';
import { PlayerProvider } from './components/PlayerProvider';

export const App = () => {
  const [album, setAlbum] = useState(null);

  return (
    <PlayerProvider>
      <Discography album={album} setAlbum={setAlbum} />
      <Player album={album} setAlbum={setAlbum} />
    </PlayerProvider>
  );
};
